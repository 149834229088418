import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/Inter/InterVariable.woff2\"}],\"variableName\":\"interVariable\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"preload\":true,\"weight\":[\"400\",\"600\"],\"subsets\":[\"latin\"],\"variable\":\"--font-jbm\"}],\"variableName\":\"jbm\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-tooltip/dist/react-tooltip.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/layoutWrappers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/css/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/boxicons/css/boxicons.min.css");
